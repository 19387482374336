import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14')
];

export const server_loads = [2];

export const dictionary = {
		"/": [3],
		"/about": [4],
		"/cart": [~5],
		"/contact": [~6],
		"/faq": [~7],
		"/how-to-prepare-property": [~8],
		"/internal/email-signature": [~9,[2]],
		"/internal/pricing": [~10,[2]],
		"/pricing": [~11],
		"/services/[serviceSlug]": [12],
		"/styles": [13],
		"/thanks/[slug]": [~14]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';